import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function({
  formControlProps,
  inputLabelProps,
  selectProps,
  label,
  options,
  onBlur,
  onChange,
  value,
  error,
}) {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const classes = useStyles();

  return <FormControl {...formControlProps}
    className={classes.formControl}
  >
    <InputLabel
      {...inputLabelProps}
      ref={inputLabel}
    >
      {label}
    </InputLabel>
    <Select
      {...selectProps}
      value={value}
      error={error}
      labelWidth={labelWidth}
      onBlur={onBlur}
      onChange={onChange}>
      {
        options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))
      }
    </Select>
  </FormControl>
}
