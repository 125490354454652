import React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import HomeIcon from "@material-ui/icons/Home"
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline"

export default function() {
  return (
    <Box pb={4}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <Box
            pt={4}
            pb={10}
            px={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            color="secondary.contrastText"
            bgcolor="secondary.dark"
          >
            <Box pb={3}>
              <HomeIcon style={{ fontSize: 100 }} />
              <Typography variant="h5">ONE-ON-ONE</Typography>
            </Box>
            <Box pb={3}>
              <Typography variant="body1">
                I offer tutoring sessions at your home or public locations.
                <br />
                <br />
                Public Location: <b>$40/hr</b>
                <br />
                In-Home Session: <b>$45/hr</b>
              </Typography>
            </Box>
            <Typography variant="caption">
              *Tutoring location must be mutually agreed upon and must be within
              area of service.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            pt={4}
            pb={10}
            px={5}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Box pb={3}>
              <PeopleOutlineIcon style={{ fontSize: 100 }} />
              <Typography variant="h5" color="primary">
                GROUP TUTORING
              </Typography>
            </Box>
            <Typography variant="body1">
              The more the merrier. I offer group tutoring sessions (2+
              students) at a discounted rate.
              <br />
              <br />2 students: <b>$30hr/student</b>
              <br />
              3-4 students: <b>$25hr/student</b>
              <br />
              5+ students: <b>$20hr/student</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
