import React from "react"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import Drawer from "@material-ui/core/Drawer"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  iconButtonColor: {
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.light,
    },
    color: "white",
  },
}))

export default function({ items, itemsOrder }) {
  const classes = useStyles()
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const history = useHistory()

  const toggleDrawer = isOpen => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setIsDrawerOpen(isOpen)
  }

  const handleButtonClick = itemKey => event => {
    console.warn(itemKey)
    setIsDrawerOpen(false)
    history.push(items[itemKey].url)
  }

  return (
    <Hidden mdUp>
      <Box flex={1} textAlign="right">
        <IconButton
          classes={{
            colorInherit: classes.iconButtonColor,
          }}
          color="inherit"
          aria-label="open drawer"
          size="medium"
          onClick={toggleDrawer(true)}
          className={clsx(isDrawerOpen && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <List>
            {itemsOrder.map(itemKey => (
              <ListItem
                button
                onClick={handleButtonClick(itemKey)}
                key={itemKey}
              >
                <ListItemText primary={items[itemKey].label} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </Hidden>
  )
}
