import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"
import Header from "components/Header"
import Footer from "components/Footer"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import axios from "axios"
import mappedComponents from "components/mappedComponents"
import theme from "themes/lexi"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"

const useStyles = makeStyles((theme) => ({
  mappedComponents: {
    [theme.breakpoints.up("sm")]: {
      // padding: `0 ${theme.spacing(3)}px`,
    },
  },
}))

function App() {
  const [routesData, setRoutesData] = React.useState()
  const [pageData, setPageData] = React.useState()
  const [headerData, setHeaderData] = React.useState()
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  React.useEffect(() => {
    axios.get("/content/header.json").then((response) => {
      setHeaderData(response.data)
    })
  }, [])

  React.useEffect(() => {
    async function getRoutesData() {
      try {
        const routesResponse = await axios.get("/content/routes.json")
        setRoutesData(routesResponse.data)
      } catch (e) {
        console.warn(e)
      }
    }
    getRoutesData()
  }, [])

  React.useEffect(() => {
    async function getPageData() {
      try {
        if (
          routesData &&
          (!pageData || pageData[":page"] !== location.pathname)
        ) {
          /* Get page data. */
          const pagePath = routesData[location.pathname]
            ? routesData[location.pathname]
            : location.pathname

          const pageResponse = await axios.get(`/content/${pagePath}.json`)
          setPageData(pageResponse.data)
        }
      } catch (e) {
        history.push("/")
      }
    }

    getPageData()
  }, [history, location, routesData, pageData])

  return pageData && headerData ? (
    <>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.meta.title}</title>
        <link rel="canonical" href={window.location} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth="md">
          <Header {...headerData} />
          <Box className={classes.mappedComponents} bgcolor="common.white">
            {pageData && pageData[":itemsOrder"]
              ? pageData[":itemsOrder"].map((itemKey) => {
                  const item = pageData[":items"][itemKey]
                  const { ":type": itemType, ...itemProps } = item
                  const MappedComponent = mappedComponents[itemType]
                  return MappedComponent ? (
                    <MappedComponent key={itemKey} {...itemProps} />
                  ) : null
                })
              : null}
          </Box>
          <Footer email="fortbendtutor@gmail.com" phone="713.206.3529" />
        </Container>
      </ThemeProvider>
    </>
  ) : null
}

export default App
