export default {
  fields: [
    {
      ":type": "TextField",
      textFieldProps: {
        id: "firstName",
        variant: "outlined",
        label: "First Name",
        margin: "normal",
        required: true,
      },
    }, {
      ":type": "TextField",
      textFieldProps: {
        id: "lastName",
        variant: "outlined",
        label: "Last Name",
        margin: "normal",
        required: true,
      },
    }, {
      ":type": "Select",
      label: "Gender",
      formControlProps: {
        variant: "outlined",
        margin: "normal",
      },
      inputLabelProps: {
        htmlFor: "gender",
      },
      selectProps: {
        native: true,
        inputProps: {
          id: "gender",
          name: "gender",
        },
      },
      options: [
        {
          value: ``,
          label: ``,
        },
        {
          value: 'female',
          label: 'Female',
        },
        {
          value: 'male',
          label: 'Male',
        },
      ],
    }, {
      ":type": "TextField",
      textFieldProps: {
        id: "comments",
        variant: "outlined",
        label: "Comments",
        margin: "normal",
        multiline: true,
        fullWidth: true,
        helperText: "Let us know if you have any thoughts or questions!"
      }
    }
  ]
}
