import React from "react"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"

export default function({ email, phone }) {
  return (
    <Box textAlign="center" bgcolor="secondary.light" p={6}>
      <Link href={`mailto: ${email}`}>{email}</Link>
      <br />
      <Link href={`tel:${{ phone }}`}>{phone}</Link>
    </Box>
  )
}
