import React from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function({
  onBlur,
  onChange,
  textFieldProps,
  value,
  error
}) {
  const classes = useStyles()

  return <TextField
    {...textFieldProps}
    className={classes.textField}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    error={error}
  />
}
