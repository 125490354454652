import React from "react"
import Box from "@material-ui/core/Box"

export default function({ src, height }) {
  return (
    <Box pb={3}>
      <iframe
        src={src}
        width="100%"
        height={height}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </Box>
  )
}
