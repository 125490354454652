import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import TextField from "components/forms/TextField"
import Select from "components/forms/Select"
import signUpForm from "forms/newStudent"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))

export default function() {
  const fields = signUpForm.fields
  const classes = useStyles()
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    gender: "",
    comments: "",
  })

  const [errors, setErrors] = React.useState({})

  const [isSubmitAttempted, setIsSubmitAttempted] = React.useState(false)

  const handleChange = id => e => {
    const value = e.target.value
    setValues({ ...values, [id]: value })
  }

  const handleBlur = id => e => {
    const value = e.target.value
    let error = ""

    if (value !== "male") {
      error = "This is a required field."
    }
    setErrors({ ...errors, [id]: error !== "" })
  }

  const handleSubmit = e => {
    e.preventDefault()
    console.warn(values)
  }

  return (
    <Container>
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {fields.map(f => {
          if (f[":type"] === "TextField") {
            return (
              <TextField
                {...f}
                key={`form-field-${f.textFieldProps.id}`}
                onBlur={handleBlur(f.textFieldProps.id)}
                onChange={handleChange(f.textFieldProps.id)}
                value={values[f.textFieldProps.id]}
                error={errors[f.textFieldProps.id]}
              />
            )
          }
          if (f[":type"] === "Select") {
            return (
              <Select
                {...f}
                key={`form-field-${f.selectProps.inputProps.id}`}
                onChange={handleChange(f.selectProps.inputProps.id)}
                onBlur={handleBlur(f.selectProps.inputProps.id)}
                value={values[f.selectProps.inputProps.id]}
                error={errors[f.selectProps.inputProps.id]}
              />
            )
          }
          return null
        })}
        <Button
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
        >
          Submit
        </Button>
      </form>
    </Container>
  )
}
