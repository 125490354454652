import Carousel from "./core/Carousel"
import Form from "./core/Form"
import GoogleForm from "./core/GoogleForm"
import RichText from "./core/RichText"
import RichTextImage from "./core/RichTextImage"
import PromoOfferings from "./core/PromoOfferings"
import PromoStats from "./core/PromoStats"
import TwoColumn from "./core/TwoColumn"

export default {
  Carousel,
  RichText,
  RichTextImage,
  PromoOfferings,
  PromoStats,
  TwoColumn,
  Form,
  GoogleForm,
}
