import React from "react"
import Slider from "react-slick"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import CardMedia from "@material-ui/core/CardMedia"
import { createMarkup } from "utils/richText"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  imageGridItem: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  title: {
    textAlign: "center",
  },
}))

export default function({ title, description, imgUrl }) {
  const classes = useStyles()

  return (
    <Box mx={4} bgcolor="">
      <Typography variant="h3" component="h1">
        {title}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={createMarkup(description)}
          ></Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          classes={{
            root: classes.imageGridItem,
          }}
        >
          <Box className={classes.image} boxShadow={3} p={2}>
            <img src={imgUrl} width="100%" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
