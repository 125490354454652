import React from "react"
import Slider from "react-slick"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { createMarkup } from "utils/richText"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  richText: {
    [theme.breakpoints.down("xs")]: {
      "& ul": {
        paddingLeft: theme.spacing(2),
      },
    },
  },
}))

export default function({ description }) {
  const classes = useStyles()

  return (
    <Box mx={4} pb={4} bgcolor="">
      <Typography
        className={classes.richText}
        variant="body1"
        dangerouslySetInnerHTML={createMarkup(description)}
      ></Typography>
    </Box>
  )
}
