import React from "react"
import Slider from "react-slick"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      margin: `0 ${theme.spacing(4)}px`,
    },
  },
  sliderContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5),
    },
  },
}))

export default function({ title, items }) {
  const classes = useStyles()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Box className={classes.root} pb={4}>
      <Box className={classes.sliderContainer} bgcolor="primary.light">
        <Slider {...settings}>
          {items.map(item => {
            return (
              <div key={item.quote}>
                <Box
                  p={5}
                  bgcolor="primary.contrastText"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box pb={3}>
                    <Typography variant="h6">{title}</Typography>
                  </Box>
                  <Box>
                    <Box pb={3} textAlign="left">
                      <Typography variant="body1">"{item.quote}"</Typography>
                    </Box>
                    <Box pr={6} textAlign="right">
                      <Typography variant="subtitle2">
                        - {item.author}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
            )
          })}
        </Slider>
      </Box>
    </Box>
  )
}
