import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
import { createMarkup } from "utils/richText"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      margin: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      padding: `${theme.spacing(3)}px 0`,
    },
    [theme.breakpoints.up("sm")]: {
      margin: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px`,
      padding: theme.spacing(5),
    },
  },
  title: {
    textAlign: "center",
  },
  richText: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      "& ul": {
        paddingLeft: theme.spacing(2),
      },
    },
  },
}))

export default function({ title, description }) {
  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      border={16}
      borderColor="secondary.light"
      boxShadow={3}
    >
      <Box textAlign="center" pb={3}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Grid container>
        <Grid item md={8}>
          <Box
            className={classes.richText}
            dangerouslySetInnerHTML={createMarkup(description)}
          ></Box>
        </Grid>
      </Grid>
    </Box>
  )
}
