import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

const palette = {
  primary: { main: "#FF6161", light: "#F38C8A", contrastText: "#FFFFFF" },
  // primary: { main: "#B71C1C" },
  secondary: { main: "#A5D8D0", dark: "#6AC8BA", contrastText: "#FFFFFF" },
  // secondary: { main: "#00695C" }
}

const themeName = "Thunderbird Tropical Rain Forest Nudibranch"

export default responsiveFontSizes(
  createMuiTheme({
    palette,
    themeName,
  }),
)
