import React from "react"
import Box from "@material-ui/core/Box"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
  contained: {
    "&:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.primary.contrastText}`,
      "&$disabled": {
        borderRight: `1px solid ${theme.palette.action.disabled}`,
      },
    },
  },
  buttonLarge: {
    padding: "8px 37px",
  },
}))

export default function ({ items, itemsOrder }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Hidden smDown>
      <Box display="flex" justifyContent="center" pb={4}>
        <ButtonGroup
          size="large"
          variant="contained"
          aria-label="navigation"
          classes={{
            groupedContainedHorizontal: classes.contained,
          }}
        >
          {itemsOrder && itemsOrder.length > 0
            ? itemsOrder.map((itemKey) => {
                const item = items[itemKey]
                return (
                  <Button
                    color="primary"
                    className={classes.button}
                    key={`md-button-${itemKey}`}
                    onClick={() => {
                      history.push(item.url)
                    }}
                    classes={{
                      containedSizeLarge: classes.buttonLarge,
                    }}
                  >
                    {item.label}
                  </Button>
                )
              })
            : null}
        </ButtonGroup>
      </Box>
    </Hidden>
  )
}
