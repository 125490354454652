import React from "react"
import Box from "@material-ui/core/Box"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "components/Drawer"
import { Link, useHistory } from "react-router-dom"
import NavBar from "components/NavBar"

const useStyles = makeStyles(theme => ({
  cta: {
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    color: theme.palette.primary.main,
    textDecorationColor: theme.palette.secondary.main,
    fontStyle: "italic",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      padding: `${theme.spacing(2)}px 0`,
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      padding: `0 ${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
  },
  logo: {
    width: 100,
  },
  button: {
    textTransform: "none",
  },
  iconButtonColor: {
    background: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.light,
    },
    color: "white",
  },
}))

export default function({
  logo,
  title,
  subtitle,
  ctaLabel,
  items,
  itemsOrder,
}) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box bgcolor="common.white" pt={2}>
      <Box display="flex" alignItems="center" px={3}>
        <Link to="/">
          <img className={classes.logo} src={logo.url} alt={logo.alt} />
        </Link>
        <Box ml={2}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Box>
        <Drawer items={items} itemsOrder={itemsOrder} />
      </Box>
      <Box className={classes.cta} display="flex">
        <Typography variant="h5">{ctaLabel}</Typography>
      </Box>
      <NavBar items={items} itemsOrder={itemsOrder} />
    </Box>
  )
}
