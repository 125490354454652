import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      margin: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      padding: `${theme.spacing(3)}px 0`,
      "& ul": {
        paddingLeft: theme.spacing(3),
      },
    },
    [theme.breakpoints.up("sm")]: {
      margin: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px`,
      padding: theme.spacing(5),
    },
  },
  title: {
    textAlign: "center",
  },
}))

export default function({ title, bullets, highlight }) {
  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      border={16}
      borderColor="secondary.light"
      boxShadow={3}
    >
      <Box textAlign="center" pb={3}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Grid container>
        <Grid item md={8}>
          <ul>
            {bullets.map(bullet => {
              return (
                <li key={bullet}>
                  <Typography variant="h5">{bullet}</Typography>
                </li>
              )
            })}
          </ul>
        </Grid>
        <Hidden smDown>
          <Grid item md={4}>
            <Box
              px={4}
              py={8}
              borderRadius="50%"
              bgcolor="primary.light"
              color="primary.contrastText"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Typography variant="h5">{highlight}</Typography>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  )
}
